import "./styrelse.css";

import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

class om extends React.Component {
  render() {
    const siteTitle = "Fornbod - Om";

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />

        
        
      </Layout>
    );
  }
}

export default om;
